<template>
  <div align="center">
    <!-- カードコンポーネントの開始 -->
    <v-card
      class="d-flex flex-column  mt-5 pt-5"
      elevation="5"
      style="background-color: white; border: 1px solid #000; margin: 10px; padding: 10px;"
    >
      <!-- 画像URLがあり、それが1つ以上の場合にカルーセルを表示 -->
      <v-carousel
        v-if="item.image_urls && item.image_urls.length > 0"
        cycle
        height="25vh"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <!-- カルーセル内の各アイテム（画像） -->
        <v-carousel-item
          v-for="(url, i) in item.image_urls"
          :key="i"
          class="rounded-sm"
          @click="openDialog(url)"
        >
          <video
            v-if="isVideo(url)"
            :src="url"
            muted
            loop
            style="object-fit: contain; width: 100%; height: 100%;"
          />
          <v-img
            v-else
            :src="url"
            cover
            style="object-fit: contain; width: 100%; height: 100%;"
          />
        </v-carousel-item>
      </v-carousel>

      <!-- テキストがある場合にタイトルを表示 -->
      <v-card-title
        v-if="item.text"
      >
        <h3 class="overflow-text">
          {{ item.text }}
        </h3>
      </v-card-title>

      <!-- Discordで見るボタン -->
      <v-row class="justify-end">
        <discord-button
          v-if="discordButton"
          :message-url="item.message_url"
        />
      </v-row>

      <!-- 画像を大きく表示するためのダイアログ -->
      <v-dialog
        v-model="dialog"
        max-width="1200px"
        min-width="90vw"
      >
        <v-card
          elevation="5"
        >
          <!-- currentImageがmp4の場合動画を表示 -->
          <video
            v-if="isVideo(currentImage)"
            :src="currentImage"
            controls
            width="100%"
          />
          <!-- currentImageがmp4以外の場合画像を表示 -->
          <v-img
            v-else
            :src="currentImage"
            contain
          />
          <v-card-actions>
            <v-spacer />
            <!-- 閉じるボタン -->
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <!-- ユーザーコンポーネントの表示 -->
    <user
      v-if="user"
      :user-id="item.user_id"
      tab="2"
    />
  </div>

  <!-- カードコンポーネントの終了 -->
</template>

<script>
  import DiscordButton from './DiscordButton.vue'

  export default {
    name: 'DiscordCard',
    components: {
      DiscordButton,
    },
    props: {
      item: { // 必要なアイテムの情報
        type: Object,
        required: true,
      },
      user: { // ユーザー情報が必要かどうか
        type: Boolean,
        default: false,
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialog: false, // ダイアログの表示/非表示
        currentImage: '', // 現在表示している画像
      }
    },
    methods: {
      openDialog (imageUrl) { // ダイアログを開くためのメソッド
        this.currentImage = imageUrl
        this.dialog = true
      },
      isVideo (url) {
        return url.endsWith('.mp4')
      },
    },
  }
</script>

<style scoped>
.rounded-image {
  border-radius: 50%;
}
.overflow-text {
  text-align: left; /* テキストを左寄せにする */
  white-space: pre-wrap; /* 改行コードを反映させる */
  word-wrap: break-word; /* 単語がはみ出す場合に改行する */
  overflow-wrap: break-word; /* 単語がはみ出す場合に改行する */
  margin-bottom: 20px; /* ここで余白を追加 */
}

</style>
