<template>
  <!-- タグの一覧を表示する -->
  <div>
    <v-select
      v-if="sortedTags.length"
      v-model="selected"
      :items="sortedTags"
      item-text="tag"
      item-value="tag"
      :label="$t('message.tag_search')"
      chips
      return-object
    >
      <template v-slot:selection="{ item }">
        <v-chip
          class="chip--select-multi"
        >
          {{ item.tag }} ({{ item.count }})
          <v-icon
            small
            @click.stop="clearSelection"
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>

      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.tag }} ({{ item.count }})
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
  </div>
</template>

<script>
  export default {
    name: 'TagChips',
    props: {
      // アイテムの一覧
      items: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        // ソート済みのタグ一覧
        sortedTags: [],
        // 選択されたタグ
        selected: null,
        // 表示しないタグ
        excludedTags: ['Tag1', 'Tag2'], // ここに表示したくないタグを指定します
      }
    },
    watch: {
      // selected の変更を監視
      selected (newVal) {
        if (newVal && newVal !== '') {
          this.$emit('input', newVal)
          this.$emit('tag-clicked', newVal.tag)
        } else if (newVal === '') {
          // 空文字の場合は何もしない
        } else {
          this.$emit('input', null)
          this.$emit('tag-clicked', null)
        }
      },
    },

    created () {
      // タグ一覧を生成する
      this.generateTagList()
    },
    methods: {
      generateTagList () {
        // タグとその出現回数を保持するオブジェクト
        const tagCount = {}

        // アイテムごとにタグを集計する
        for (const item of this.items) {
          // タグが存在しない場合はエラーとする
          if (!item.tags || !Array.isArray(item.tags)) {
            console.error('Invalid tags: ', item.tags)
            continue
          }

          for (const tag of item.tags) {
            if (!tagCount[tag]) {
              tagCount[tag] = 0
            }
            tagCount[tag]++
          }
        }

        // タグとその出現回数を配列に変換する
        const tags = []
        for (const tag in tagCount) {
          if (!this.excludedTags.includes(tag)) {
            tags.push({ tag: tag, count: tagCount[tag] })
          }
        }

        // タグを出現回数でソートする
        this.sortedTags = tags.sort((a, b) => b.count - a.count)
      },
      clearSelection () {
        this.selected = { tag: null, count: null }
        // this.$emit('tag-clicked', null)
      },
      clearTag () {
        this.selected = ''
        // this.$emit('tag-clicked', null)
      },
    },
  }
</script>
