<template>
  <v-dialog v-model="menu" max-width="500" persistent>
    <v-card>
      <!-- カラーピッカー -->
      <v-color-picker
        flat
        hide-canvas
        hide-inputs
        hide-sliders
        :value="internalColor"
        :swatches="getAllColors()"
        @input="updateColor"
      ></v-color-picker>
      <v-card-actions>
        <!-- 選択中の色と色名を動的に表示 -->
        <v-chip
          :style="{
            backgroundColor: internalColor,
            color: '#000',
            fontFamily: 'Mochiy Pop One, sans-serif',
          }"
          label
          small
        >
          {{ selectedColorName || '選択中' }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn text @click="close">close</v-btn>
      </v-card-actions>

      <!-- 中分類用の v-expansion-panels -->
      <v-expansion-panels>
        <v-expansion-panel v-for="(category, index) in categories" :key="index">
          <!-- パネルヘッダーの背景色をカテゴリの最初の色に設定 -->
          <v-expansion-panel-header
            :style="{
              backgroundColor: category.colors[0].color,
              color: '#000',
              fontFamily: 'Mochiy Pop One, sans-serif',
            }"
          >
            {{ category.name }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list class="scrollable-list">
              <v-list-item
                v-for="(swatch, swatchIndex) in category.colors"
                :key="swatchIndex"
                @click="selectColor(swatch)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <div
                      :style="{
                        color: '#000',
                        fontFamily: 'Mochiy Pop One, sans-serif',
                      }"
                    >
                      {{ swatch.name }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar :color="swatch.color"></v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ColorPickerDialog',
    data() {
      return {
        menu: false,
        internalColor: '', // 選択した色を格納するプロパティ
        selectedColorName: '', // 選択中の色名を格納するプロパティ
        // 中分類用のカテゴリ
        categories: [
          {
            name: '白系',
            colors: [
              { name: 'スノウホワイト', color: '#E4DFD0' },
              { name: 'アッシュグレイ', color: '#ACA8A2' },
              { name: 'グゥーブーグレイ', color: '#898784' },
              { name: 'スレートグレイ', color: '#656565' },
              { name: 'チャコールグレイ', color: '#484742' },
              { name: 'スートブラック', color: '#2b2923' },
            ],
          },
          {
            name: '赤系',
            colors: [
              { name: 'ローズピンク', color: '#E69F96' },
              { name: 'ライラックパープル', color: '#836969' },
              { name: 'ロランベリーレッド', color: '#5B1729' },
              { name: 'ダラガブレッド', color: '#781A1A' },
              { name: 'ラストレッド', color: '#622207' },
              { name: 'ワインレッド', color: '#451511' },
              { name: 'コーラルピンク', color: '#CC6C5E' },
              { name: 'ブラッドレッド', color: '#913B27' },
              { name: 'サーモンピンク', color: '#E4AA8A' },
              { name: 'ルビーレッド', color: '#E40011' },
              { name: 'チェリーピンク', color: '#F5379B' },
            ],
          },
          {
            name: '茶系',
            colors: [
              { name: 'サンセットオレンジ', color: '#B75C2D' },
              { name: 'メサレッド', color: '#7D3906' },
              { name: 'バークブラウン', color: '#6A4B37' },
              { name: 'チョコレートブラウン', color: '#6E3D24' },
              { name: 'ラセットブラウン', color: '#4F2D1F' },
              { name: 'コボルドブラウン', color: '#30211B' },
              { name: 'コルクブラウン', color: '#C99156' },
              { name: 'キキルンブラウン', color: '#996E3F' },
              { name: 'オポオポブラウン', color: '#7B5C2D' },
              { name: 'アルドゴートブラウン', color: '#A2875C' },
              { name: 'パンプキンオレンジ', color: '#C57424' },
              { name: 'エーコンブラウン', color: '#8E581B' },
              { name: 'オーチャードブラウン', color: '#644216' },
              { name: 'チェスナットブラウン', color: '#3D290D' },
              { name: 'ゴブリンブラウン', color: '#B9A489' },
              { name: 'シェールブラウン', color: '#92816C' },
              { name: 'モールブラウン', color: '#615245' },
              { name: 'ロームブラウン', color: '#3F3329' },
            ],
          },
          {
            name: '黄系',
            colors: [
              { name: 'ボーンホワイト', color: '#EBD3A0' },
              { name: 'ウルダハンブラウン', color: '#B7A370' },
              { name: 'デザートイエロー', color: '#DBB457' },
              { name: 'ハニーイエロー', color: '#FAC62B' },
              { name: 'ミリオンコーンイエロー', color: '#E49E34' },
              { name: 'クァールイエロー', color: '#BC8804' },
              { name: 'クリームイエロー', color: '#F2D770' },
              { name: 'ハラタリイエロー', color: '#A58430' },
              { name: 'レーズンブラウン', color: '#403311' },
              { name: 'カナリーイエロー', color: '#FEF864' },
              { name: 'バニライエロー', color: '#FBF1B4' },
            ],
          },
          {
            name: '緑系',
            colors: [
              { name: 'マッドグリーン', color: '#585230' },
              { name: 'シルフグリーン', color: '#BBBB8A' },
              { name: 'ライムグリーン', color: '#ABB054' },
              { name: 'モスグリーン', color: '#707326' },
              { name: 'メドウグリーン', color: '#8B9C63' },
              { name: 'オリーヴグリーン', color: '#4B5232' },
              { name: 'マーシュグリーン', color: '#323621' },
              { name: 'アップルグリーン', color: '#9BB363' },
              { name: 'サボテンダーグリーン', color: '#658241' },
              { name: 'ハンターグリーン', color: '#284B2C' },
              { name: 'オチューグリーン', color: '#406339' },
              { name: 'アダマンタスグリーン', color: '#5F7558' },
              { name: 'ノフィカグリーン', color: '#3B4D3C' },
              { name: 'ディープウッドグリーン', color: '#1E2A21' },
              { name: 'セレストグリーン', color: '#96BDB9' },
              { name: 'ターコイズグリーン', color: '#437272' },
              { name: 'モルボルグリーン', color: '#1F4646' },
            ],
          },
          {
            name: '青系',
            colors: [
              { name: 'アイスブルー', color: '#B2C4CE' },
              { name: 'スカイブルー', color: '#83B0D2' },
              { name: 'シーフォグブルー', color: '#6481A0' },
              { name: 'ピーコックブルー', color: '#3B6886' },
              { name: 'ロータノブルー', color: '#1C3D54' },
              { name: 'コープスブルー', color: '#8E9BAC' },
              { name: 'セルレアムブルー', color: '#4F5766' },
              { name: 'ウォードブルー', color: '#2F3851' },
              { name: 'インクブルー', color: '#1A1F27' },
              { name: 'ラプトルブルー', color: '#5B7FC0' },
              { name: 'オサードブルー', color: '#2F5889' },
              { name: 'ストームブルー', color: '#234172' },
              { name: 'ヴォイドブルー', color: '#112944' },
              { name: 'ロイヤルブルー', color: '#273067' },
              { name: 'ミッドナイトブルー', color: '#181937' },
              { name: 'シャドウブルー', color: '#373747' },
              { name: 'アビサルブルー', color: '#312D57' },
              { name: 'ドラグーンブルー', color: '#000EA2' },
              { name: 'ターコイズブルー', color: '#04AFCD' },
            ],
          },
          {
            name: '紫系',
            colors: [
              { name: 'ラベンダーブルー', color: '#877FAE' },
              { name: 'グルームパープル', color: '#514560' },
              { name: 'カラントパープル', color: '#322C3B' },
              { name: 'アイリスパープル', color: '#B79EBC' },
              { name: 'グレープパープル', color: '#3B2A3D' },
              { name: 'ロータスピンク', color: '#FECEF5' },
              { name: 'コリブリピンク', color: '#DC9BCA' },
              { name: 'プラムパープル', color: '#79526C' },
              { name: 'リーガルパープル', color: '#66304E' },
            ],
          },
          {
            name: 'レア系',
            colors: [
              { name: 'ピュアホワイト', color: '#F9F8F4' },
              { name: 'ジェットブラック', color: '#1E1E1E' },
              { name: 'パステルピンク', color: '#FDC8C6' },
              { name: 'ダークレッド', color: '#321919' },
              { name: 'ダークブラウン', color: '#28211C' },
              { name: 'パステルグリーン', color: '#BACFAA' },
              { name: 'ダークグリーン', color: '#152C2C' },
              { name: 'パステルブルー', color: '#96A4D9' },
              { name: 'ダークブルー', color: '#121F2D' },
              { name: 'パステルパープル', color: '#BBB5DA' },
              { name: 'ダークパープル', color: '#232026' },
              { name: 'シャインシルバー', color: '#A7A7A7' },
              { name: 'シャインゴールド', color: '#EDE63D' },
              { name: 'メタリックレッド', color: '#B61D4B' },
              { name: 'メタリックオレンジ', color: '#E48C2F' },
              { name: 'メタリックイエロー', color: '#F4DA46' },
              { name: 'メタリックグリーン', color: '#22A757' },
              { name: 'メタリックスカイブルー', color: '#5ED4E6' },
              { name: 'メタリックブルー', color: '#5052D9' },
              { name: 'メタリックパープル', color: '#A366C2' },
              { name: 'ガンメタル', color: '#606068' },
              { name: 'パールホワイト', color: '#E9E3DA' },
              { name: 'シャインブラス', color: '#E7D197' },
            ],
          },
        ],
      };
    },
    methods: {
      open(index) {
        this.menu = true;
        this.currentIndex = index; // 現在選択している色のインデックスを保存
      },
      close() {
        this.menu = false;
        this.$emit('input', this.internalColor);
      },
      selectColor(swatch) {
        this.internalColor = swatch.color;
        this.selectedColorName = swatch.name;

        console.log('Swatch Color:', swatch.color); // デバッグ情報
        console.log('Swatch Name:', swatch.name); // デバッグ情報

        // 選択した色とインデックスを親に渡す
        this.$emit(
          'colorSelected',
          {
            color: this.internalColor,
            name: this.selectedColorName,
          },
          this.currentIndex
        ); // インデックスも一緒に渡す

        this.menu = false; // ダイアログを閉じる
      },
      updateColor(color) {
        this.internalColor = color;
        const selectedSwatch = this.categories
          .flatMap((category) => category.colors)
          .find((swatch) => swatch.color === color);
        if (selectedSwatch) {
          this.selectedColorName = selectedSwatch.name; // 色名をセット
        }
        this.$emit('input', this.internalColor);
      },
      getAllColors() {
        return this.categories.flatMap((category) =>
          category.colors.map((swatch) => swatch.color)
        );
      },
    },
  };
</script>

<style scoped>
  .scrollable-list {
    max-height: 200px; /* スクロールバーが出る高さを設定 */
    overflow-y: auto; /* 縦方向のスクロールを有効化 */
  }
</style>
