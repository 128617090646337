<template>
  <v-btn
    :color="isFavorite ? 'grey' : 'success'"
    @click="toggleFavorite"
  >
    {{ isFavorite ? $t("message.delete_favorite") : $t("message.add_favorite") }}
  </v-btn>
</template>

<script>
  export default {
    name: 'FavoriteButton',
    props: {
      tweetId: {
        type: String,
        required: true,
      },
      initialFavorite: { // 追加
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        isFavorite: this.initialFavorite, // 初期値をpropsから受け取る
      }
    },
    methods: {
      toggleFavorite () {
        this.isFavorite = !this.isFavorite
        this.$emit('favorite-toggled', this.tweetId, this.isFavorite)
        this.$emit('favorite-updated') // お気に入りのデータが更新されたことを通知
      },
    },
  }
</script>
