<template>
  <v-dialog
    :key="dialogKey"
    :value="value"
    width="80%"
    @input="$emit('input', $event)"
  >
    <v-card
      color="grey-lighten-4"
      flat
    >
      <v-toolbar
        :color="getEventColor(selectedEvent)"
        dark
      >
        <v-toolbar-title v-html="selectedEvent.name" />
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-carousel
          v-if="selectedEvent.image_urls && selectedEvent.image_urls.length > 0"
          hide-delimiters
          cycle
          height="60vh"
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(img, i) in selectedEvent.image_urls"
            :key="i"
            show-arrows-on-hover
            cycle
          >
            <v-img
              :src="img"
              contain
              height="100%"
              width="100%"
            />
          </v-carousel-item>
        </v-carousel>
        <div class="centered-text-container">
          <p v-html="formatWithLinks(formattedText)" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          variant="text"
          color="secondary"
          @click="closeDialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'EventDialog',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      selectedEvent: {
        type: Object,
        required: true,
      },
      formattedText: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        dialogKey: 0, // キーの初期値
      }
    },
    watch: {
      value (val) {
        if (val) {
          this.dialogKey++ // ダイアログが開くたびにキーをインクリメント
        }
      },
    },
    methods: {
      formatWithLinks (text) {
        const segments = text.split('<br>')
        const formattedSegments = segments.map(segment => {
          // URLを検出し、aタグで囲む正規表現を使用します
          const urlRegex = /(https?:\/\/[^\s<]+)/g
          return segment.replace(urlRegex, url => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`)
        })
        return formattedSegments.join('<br>')
      },
      closeDialog () {
        this.$emit('input', false)
      },
      // イベントの色を取得
      getEventColor (event) {
        switch (event.dc) {
          case 'Mana':
            return 'red'
          case 'Elemental':
            return 'blue'
          case 'Gaia':
            return 'green'
          case 'Meteor':
            return 'orange'
          case 'Other':
            return 'brown'
          default:
            return 'purple'
        }
      },
    },
  }
</script>

<style scoped>
.centered-text-container {
  width: 100%; /* 必要に応じて調整 */
  justify-content: center;
  display: flex;
  text-align: left;
  margin-top: 20px; /* こちらで上部のマージンを20pxに設定。適切な値に調整してください */
  word-wrap: break-word; /* 長い単語やURLが含まれている場合の折り返しを強制 */
  max-width: 100%;
}

.centered-text-container p {
  word-wrap: break-word; /* 長い単語やURLが含まれている場合の折り返しを強制 */
  word-break: break-all;
  flex-shrink: 1;
  color: black;
}
</style>
