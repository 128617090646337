<template>
  <v-card
    class="mt-4 text-center"
  >
    <v-img
      :src="item.avatar_url"
      contain
    />

    <v-chip
      v-if="isNew"
      outlined
      color="white"
      text-color="#e91e63"
    >
      <v-icon>mdi-alert-circle-outline</v-icon>
      UPDATE
    </v-chip>
    <v-card-text class="text-center">
      <h6 class="text-h6 mb-2 text--secondary">
        {{ item.user_name }}
      </h6>

      <router-link :to="{ name: 'Artwork', params: { id: item.user_id } }">
        <v-btn
          v-if="showButton"
          class="mr-0"
          color="primary"
          min-width="100"
          rounded
        >
          {{ $t("message.view_the_artwork") }}
        </v-btn>
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'HousingerCard',

    props: {
      item: {
        type: Object,
        required: true,
      },
      showButton: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      isNew: function () {
        const itemUpdateDate = moment(this.item.update_date)
        const daysSinceUpdate = moment().diff(itemUpdateDate, 'days')
        return daysSinceUpdate <= 3
      },
    },
  }
</script>
