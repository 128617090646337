<template>
  <div>
    <a
      :href="tweetUrl"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-img
        :src="src"
        contain
        width="100%"
        max-height="80vh"
        @click.stop
      >
        <template v-slot:placeholder>
          <div
            class="fill-height ma-0 d-flex align-center justify-center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </div>
        </template>
      </v-img>
    </a>
    <!-- 作者名の表示 -->
    <div
      class="d-flex justify-center align-center"
      style="margin-bottom: 20px;"
    >
      <a
        :href="authorTwitter"
        target="_blank"
        rel="noopener noreferrer"
        style="text-decoration: none;"
      >
        <v-chip
          variant="text"
          text-color="black"
        >
          投稿者:{{ authorName }}
        </v-chip>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Photo',
    props: {
      src: {
        type: String,
        required: true,
      },
      authorTwitter: {
        type: String,
        default: '#',
      },
      tweetUrl: {
        type: String,
        default: '#',
      },
      authorName: {
        type: String,
        default: 'Unknown',
      },
    },
  }
</script>
