<template>
  <v-chip
    v-clipboard:copy="onTag"
    v-clipboard:success="onCopy"
    v-clipboard:error="onError"
    dark
    class="ma-2"
    label
    text-color="primary"
    color="primary"
    outlined
  >
    <v-icon left>
      mdi-content-copy
    </v-icon>
    {{ $t("message.copy_commission_open_tag") }}
  </v-chip>
</template>

<script>
  export default {
    name: 'CopyCommissionOpen',
    data: function () {
      return {
        onTag: '#HousingEdenCommissionOpen',
      }
    },
    methods: {
      onCopy: function (e) {
        alert('Copied')
        console.log('You just copied: ' + e.text)
      },
      onError: function (e) {
        console.log('Failed to copy texts')
      },
    },
  }
</script>
