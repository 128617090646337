<template>
  <section class="container">
    <EstoreCard />
    <div class="input-area">
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <!-- 検索バー部分 -->
        <v-text-field
          v-model="inputWordYoutube"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('message.search')"
          @input="onInputChangeYoutube"
          @click:clear="clearTagSelection"
          @change="clearTag"
        />
      </v-toolbar>
    </div>

    <!-- タグのリスト -->
    <div class="scrollable-container">
      <tag-chips
        ref="tagChipsRef"
        :items="items"
        @tag-clicked="setSearchWord"
      />
    </div>

    <v-container fluid>
      <v-row>
        <v-col
          v-for="(item, index) in itemsYoutube"
          :key="index"
          cols="12"
          sm="10"
          md="6"
          lg="6"
          xl="3"
        >
          <!-- Youtubeカード部分 -->
          <youtube-card
            :item="item"
            :user="user"
            :discord-button="discordButton"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- 無限スクロール部分 -->
    <infinite-loading
      :identifier="infiniteIdYoutube"
      @infinite="infiniteHandlerYoutube"
    >
      <v-alert
        slot="no-results"
        dense
        text
        type="success"
      >
        {{ $t("message.no-results") }}
      </v-alert>
      <v-alert
        slot="no-more"
        dense
        text
        type="success"
      >
        {{ $t("message.no-more") }}
      </v-alert>
      <v-alert
        slot="error"
        dense
        outlined
        type="error"
      >
        {{ $t("message.infinite-error") }}
      </v-alert>
    </infinite-loading>
  </section>
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading'
  import YoutubeCard from './YoutubeCard.vue'
  import TagChips from './TagChips.vue'

  export default {
    name: 'YoutubeTab',
    components: {
      InfiniteLoading,
      YoutubeCard,
      TagChips,
    },
    props: {
      user: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
        default: () => [],
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        inputWordYoutube: '', // ユーザーが入力した検索文字列
        itemsYoutube: [], // 表示するYouTubeのリスト
        searchedItemsYoutube: [], // 検索結果のリスト
        startYoutube: 0,
        perData: 4, // 一度に表示するデータの数
        getDataYoutube: 0,
        infiniteIdYoutube: 0, // 無限スクロールの識別子
      }
    },
    methods: {
      clearTagSelection () {
        this.$refs.tagChipsRef.clearSelection()
      },
      clearTag () {
        this.$refs.tagChipsRef.clearTag()
      },

      // 検索結果を初期化
      initializeSearchResults () {
        this.infiniteIdYoutube += 1
        this.searchedItemsYoutube = [...this.items]
        this.itemsYoutube = []
        this.startYoutube = 0
      },
      // YouTubeデータをロードする
      dataLoadYoutube () {
        this.itemsYoutube.push(
          ...this.searchedItemsYoutube.slice(
            this.startYoutube,
            this.startYoutube + this.perData,
          ),
        )
        this.startYoutube = this.startYoutube + this.perData
      },
      // 無限スクロールのハンドラー
      async infiniteHandlerYoutube ($state) {
        if (this.getDataYoutube === 0) {
          this.initializeSearchResults()

          this.getDataYoutube = 1
        }
        this.dataLoadYoutube()
        if (this.searchedItemsYoutube.length <= this.startYoutube) {
          $state.complete()
        } else {
          $state.loaded()
        }
      },
      // 入力が変更されたときのハンドラー
      onInputChangeYoutube () {
        // this.clearTagSelection()
        // 検索処理
        this.infiniteIdYoutube += 1
        this.itemsYoutube = []
        this.$nextTick(() => {
          this.initializeSearchResults()
          if (this.inputWordYoutube && typeof this.inputWordYoutube === 'string') {
            this.searchedItemsYoutube = this.searchedItemsYoutube.filter(item =>
              item && item.text && typeof item.text === 'string' && item.text.toLowerCase().includes(this.inputWordYoutube.toLowerCase()),
            )
          }
          this.dataLoadYoutube()
        })
        // }
      },
      setSearchWord (tag) {
        this.inputWordYoutube = tag
        this.onInputChangeYoutube()
      },
    },
  }
</script>

<style scoped>
.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
