<template>
  <div>
    <a
      class="twitter-timeline"
      href="https://twitter.com/ff14housingeden?ref_src=twsrc%5Etfw"
    >Tweets by ff14housingeden</a>
  </div>
</template>

<script
      async
      src="https://platform.twitter.com/widgets.js"
      charset="utf-8"
    />

<script>
  export default {
    name: 'EdenTimeline',
  }
</script>
