<template>
  <div>
    <v-btn
      color="primary"
      :icon="isMobile"
      @click="dialog = true"
    >
      <v-icon>mdi-table</v-icon>
      <span v-if="!isMobile">{{ $t("message.view_in_tabular_format") }}</span>
    </v-btn>

    <v-dialog v-model="dialog">
      <v-card style="display: flex; flex-direction: column; height: 80vh;">
        <div style="overflow-y: auto; flex-grow: 1;">
          <v-card-text>
            <v-simple-table
              dense
              fixed-header
              height="68vh"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      User Name
                    </th>
                    <th class="text-left">
                      Tweet URL
                    </th>
                    <th class="text-left">
                      Address
                    </th>
                    <th class="text-left">
                      Size
                    </th>
                    <th class="text-left">
                      Aetheryte (JP)
                    </th>
                    <th class="text-left">
                      Aetheryte (EN)
                    </th>
                    <th class="text-left">
                      Aetheryte (KO)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in tableData"
                    :key="item.tweet_id"
                  >
                    <td>{{ item.user_name || '' }}</td>
                    <td>
                      <a
                        :href="item.tweet_url || '#'"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ item.tweet_url || '' }}
                      </a>
                    </td>
                    <td>{{ (item.adress || []).join(', ') }}</td>
                    <td>{{ item.size || '' }}</td>
                    <td>{{ item.aetheryteJp || '' }}</td>
                    <td>{{ item.aetheryteEn || '' }}</td>
                    <td>{{ item.aetheryteKo || '' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="downloadData"
          >
            {{ $t("message.download_data") }}
          </v-btn>
          <v-btn
            variant="text"
            color="secondary"
            @click="dialog = false"
          >
            {{ $t("message.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'TableButton',
    props: {
      tableData: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      isMobile () {
        // 600px以下の画面幅をモバイルとみなす
        return window.innerWidth <= 600
      },
    },
    methods: {
      downloadData () {
        const header = 'User Name,Tweet URL,Address,Size,Aetheryte (JP),Aetheryte (EN),Aetheryte (KO)\n'
        const csvData = this.tableData.map(item => {
          return [
            item.user_name || '',
            item.tweet_url || '',
            (item.adress || []).join(', '),
            item.size || '',
            item.aetheryteJp || '',
            item.aetheryteEn || '',
            item.aetheryteKo || '',
          ].join(',')
        }).join('\n')
        const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'data.csv')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
    },
  }
</script>

<style scoped>
/* CSSでテーブルの横スクロールバーを常に表示 */
table {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

</style>
