import { render, staticRenderFns } from "./ArtworkTabs.vue?vue&type=template&id=42d0905d&scoped=true&"
import script from "./ArtworkTabs.vue?vue&type=script&lang=js&"
export * from "./ArtworkTabs.vue?vue&type=script&lang=js&"
import style0 from "./ArtworkTabs.vue?vue&type=style&index=0&id=42d0905d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d0905d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAppBar,VBtn,VCard,VIcon,VImg,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VTooltip})
