<template>
  <div align="center">
    <!-- ビデオカードの定義 -->
    <v-card
      elevation="5"
      style="background-color: white; border: 1px solid #000; margin: 10px; padding: 10px;"
    >
      <v-responsive
        :aspect-ratio="16/9"
        class="youtube-video"
      >
        <iframe
          height="100%"
          width="100%"
          :src="youtubeEmbedUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </v-responsive>
      <v-card-title
        v-if="item.text"
      >
        <h3 class="overflow-text">
          {{ item.text }}
        </h3>
      </v-card-title>

      <!-- Discordで見るボタン -->
      <v-row class="justify-end">
        <discord-button
          v-if="discordButton"
          :message-url="item.message_url"
        />
      </v-row>
    </v-card>
    <!-- ユーザー部分 -->
    <user
      v-if="user"
      :user-id="item.user_id"
      tab="1"
    />
  </div>
</template>

<script>
  import User from './User.vue'
  import DiscordButton from './DiscordButton.vue'

  export default {
    name: 'YoutubeCard',
    components: {
      User,
      DiscordButton,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      user: {
        type: Boolean,
        default: false,
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
      autoplay: {
        type: Number,
        default: 0, // 1 means the video will autoplay by default
      },
      mute: {
        type: Number,
        default: 1, // 1 means the video will be muted by default
      },
    },
    computed: {
      playerVars () {
        return {
          autoplay: this.autoplay,
          mute: this.mute,
        }
      },
      youtubeEmbedUrl () {
        return `https://www.youtube.com/embed/${this.item.youtube_id}?autoplay=${this.playerVars.autoplay}&mute=${this.playerVars.mute}`
      },
    },
    methods: {
      // ビデオ再生時の動作
      playing () {
        console.log('we are watching!!!')
      },
      // エラー発生時の動作
      youtubeError () {
        console.log('Youtube error')
      },
    },
  }
</script>

<style scoped>
.overflow-text {
  text-align: left; /* テキストを左寄せにする */
  white-space: pre-wrap; /* 改行コードを反映させる */
  word-wrap: break-word; /* 単語がはみ出す場合に改行する */
  overflow-wrap: break-word; /* 単語がはみ出す場合に改行する */
  margin-bottom: 20px; /* ここで余白を追加 */
}
</style>
