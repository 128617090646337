<template>
  <div>
    <!-- アイテム表示部分 -->
    <div style="position: relative; display: inline-block;">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-img
            :src="item.image_url"
            width="9vw"
            min-width="100px"
            min-height="100px"
            height="9vw"
            @click="openDialog"
            v-on="on"
          />
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>

      <div
        v-if="String(item.staining) === '1'"
        style="width: 20px; height: 20px; z-index: 1; position: absolute; top: 2px; right: 2px; border-radius: 10px; border: 3px solid #3f3f3f; background-color: rgba(128, 128, 128, 0.4);"
      />

      <div
        v-if="String(item.ex) === '1'"
        style="width: 20px; height: 20px; z-index: 1; position: absolute; top: 2px; left: 2px; border-radius: 10px; border: 3px solid #3f3f3f; background-color: rgba(128, 128, 128, 0.4); display: flex; justify-content: center; align-items: center; font-size: 12px; color: white;"
      >
        EX
      </div>
    </div>

    <!-- ダイアログ表示部分 -->
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card
        v-if="item"
        class="mt-4 text-center"
      >
        <div
          v-if="!currentItemImages.length"
          style="position: relative; display: inline-block;"
        >
          <v-img
            :src="item.image_url"
            width="9vw"
            min-width="100px"
            min-height="100px"
            height="9vw"
            style="margin-top: 20px;"
          />

          <!-- 染色可能 (staining) の表示 -->
          <div
            v-if="String(item.staining) === '1'"
            style="margin-top: 20px; width: 20px; height: 20px; z-index: 1; position: absolute; top: 2px; right: 2px; border-radius: 10px; border: 3px solid #3f3f3f; background-color: rgba(128, 128, 128, 0.4);"
          />

          <!-- EX の表示 -->
          <div
            v-if="String(item.ex) === '1'"
            style="margin-top: 20px; width: 20px; height: 20px; z-index: 1; position: absolute; top: 2px; left: 2px; border-radius: 10px; border: 3px solid #3f3f3f; background-color: rgba(128, 128, 128, 0.4); display: flex; justify-content: center; align-items: center; font-size: 12px; color: white;"
          >
            EX
          </div>
        </div>

        <!-- 画像カルーセル -->
        <v-carousel
          v-if="currentItemImages.length"
          height="35vh"
        >
          <v-carousel-item
            v-for="(image, index) in currentItemImages"
            :key="index"
          >
            <img
              :src="image.src"
              :style="{ width: '100%', height: '100%', 'aspect-ratio': image.aspectRatio, 'object-fit': 'contain' }"
              @click="showImageDialog(image)"
            >
          </v-carousel-item>
        </v-carousel>

        <v-card-text
          class="text-center"
          min-height="500px"
        >
          <h6 class="text-h5 mb-3 text--primary">
            <div v-html="item.html" />
          </h6>
          <h6 class="text-h6 mb-2 text--secondary">
            {{ item.type }}
          </h6>
          <h4 class="text--secondary">
            {{ item.remarks }}
          </h4>
          <h5
            v-if="item.supplement"
            class="text--secondary"
          >
            {{ $t("message.supplement") }}:{{ item.supplement }}
          </h5>

          <h4
            v-if="item.shop"
            class="text--secondary"
          >
            {{ $t("message.shop_text") }} {{ item.shop }}
          </h4>
          <a
            v-if="item.shop_url"
            :href="item.shop_url"
            target="_blank"
          >{{ $t("message.shop_address") }}</a>

          <h6
            style="margin-top: 20px;"
            class="text-h6 mb-2 text--secondary"
          >
            {{ $t("message.quantity_sub") }}
          </h6>

          <div class="d-flex align-center justify-center">
            <div class="narrow-container">
              <v-row class="align-center">
                <v-col cols="12">
                  <v-text-field
                    v-model="item.num"
                    :label="$t('message.quantity')"
                    outlined
                    hide-spin-buttons
                    type="number"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    @click:append-outer="incrementNum"
                    @click:prepend="decrementNum"
                    @input="makeList(item)"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            variant="text"
            color="primary"
            @click="copyToClipboard(item.name)"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
            {{ $t("message.copy_furniture_name") }}
          </v-btn>
          <furniture-list
            class="button-spacing"
          />
          <v-btn
            icon
            color="secondary"
            @click="closeDialog"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import FurnitureList from '../components/FurnitureList.vue'

  export default {
    name: 'FurnishingDisplay',
    components: {
      FurnitureList,
    },
    props: {
      item: Object,
    },
    data () {
      return {
        furnishingsData: [], // 読み込んだJSONデータを格納
        currentItemImages: [], // 現在選択されたアイテムの画像パス
        imageDialog: false, // ダイアログの表示状態
        selectedImage: '', // 選択された画像のURL
        furnitureList: [], // 追加されたアイテムを保持するための新しい配列
        dialog: false,
      }
    },
    watch: {
      dialog (newVal) {
        if (newVal) {
          this.loadList()
        }
        if (newVal && this.item) {
          this.setCurrentItemImages(this.item.name)
          this.loadFurniturePicturesData() // 新しいアイテムの画像をロード
        }
      },
    },
    mounted () {
      this.loadFurnishingsData()
    },
    methods: {
      // furniture_pictures.jsonからデータをロードし、指定されたアイテム名の画像を追加するメソッド
      async loadFurniturePicturesData () {
        try {
          const timestamp = new Date().getTime()
          const response = await fetch(`../../../furniturePictures/furniture_pictures.json?timestamp=${timestamp}`)
          const furniturePicturesData = await response.json()

          // 指定されたアイテム名に一致する画像を currentItemImages に追加
          const currentItem = this.item // 現在選択されているアイテム
          if (currentItem) {
            const matchingItems = furniturePicturesData.filter(item => item.text === currentItem.name)
            matchingItems.forEach(item => {
              item.resized_image_urls.forEach(imageUrl => {
                this.currentItemImages.push({
                  src: imageUrl,
                  aspectRatio: '16/9', // 適宜アスペクト比を設定
                })

                // アスペクト比を計算する
                this.currentItemImages.forEach((imageObj, index) => {
                  const img = new Image()
                  img.onload = () => {
                    const aspectRatio = img.naturalWidth / img.naturalHeight
                    this.$set(this.currentItemImages, index, { ...imageObj, aspectRatio: aspectRatio.toString() })
                  }
                  img.src = imageObj.src
                })
              })
            })
          }
        } catch (error) {
          console.error('furniture_pictures.jsonの読み込みに失敗しました:', error)
        }
      },
      showImageDialog (image) {
        this.selectedImage = image
        this.imageDialog = true
      },
      // JSONファイルを読み込むメソッド
      async loadFurnishingsData () {
        try {
          const timestamp = new Date().getTime()
          const response = await fetch(`../../../furnishings_img.json?timestamp=${timestamp}`)
          this.furnishingsData = await response.json()
        } catch (error) {
          console.error('JSONファイルの読み込みに失敗しました:', error)
        }
      },

      // 特定のアイテム名に基づいて画像パスを設定するメソッド
      setCurrentItemImages (itemName) {
        const itemEntry = this.furnishingsData.find(item => String(item.text) === String(itemName))
        if (itemEntry) {
          this.currentItemImages = itemEntry.image_paths.map(imagePath => {
            return {
              src: imagePath,
              aspectRatio: '16/9', // 初期アスペクト比を設定
            }
          })

          // アスペクト比を計算する
          this.currentItemImages.forEach((imageObj, index) => {
            const img = new Image()
            img.onload = () => {
              const aspectRatio = img.naturalWidth / img.naturalHeight
              this.$set(this.currentItemImages, index, { ...imageObj, aspectRatio: aspectRatio.toString() })
            }
            img.src = imageObj.src
          })
        } else {
          this.currentItemImages = []
        }
      },

      openDialog () {
        this.dialog = true
      },
      closeDialog () {
        this.dialog = false
      },
      incrementNum () {
        this.loadList()
        this.item.num++
        this.makeList(this.item)
      },
      decrementNum () {
        this.loadList()
        if (this.item.num > 0) {
          this.item.num--
          this.makeList(this.item)
        }
      },
      // ローカルストレージからリストを復元するメソッド
      loadList () {
        const savedList = localStorage.getItem('furnitureList')
        if (savedList) {
          this.furnitureList = JSON.parse(savedList)
          const itemInList = this.furnitureList.find(ai => String(ai.name) === String(this.item.name))
          if (itemInList) {
            this.item.num = itemInList.num
          }
        }
      },

      // ローカルストレージにリストを保存するメソッド
      saveList () {
        localStorage.setItem('furnitureList', JSON.stringify(this.furnitureList))
      },

      makeList: function (item) {
        // 数字が入力されており、かつ値が空でないことを確認
        if (item.num !== null && !isNaN(item.num)) {
          // 数量が0の場合、リストから削除する
          if (String(item.num) === '0') {
            const index = this.furnitureList.findIndex(itemInList => itemInList.name === item.name)
            if (index !== -1) {
              this.furnitureList.splice(index, 1)
            }
          } else {
            // すでにリストに同じ名前のアイテムがあるかチェックする
            const existingItem = this.furnitureList.find(itemInList => itemInList.name === item.name)
            if (existingItem) {
              // 既存のアイテムがあれば、数量を更新する
              existingItem.num = parseInt(item.num, 10)
            } else {
              // なければ新しいアイテムをリストに追加する
              this.furnitureList.push({
                imagePath: item.image_url,
                name: item.name,
                type: item.type,
                num: parseInt(item.num, 10), // 数値に変換してから追加
              })
            }
          }
          // ローカルストレージにリストを保存する
          this.saveList()
        } else {
          // 数量が無効な場合（空または数字以外）の処理
          alert('有効な数字を入力してください。')
        }
      },
      async copyToClipboard (text) {
        if (!navigator.clipboard) {
          // クリップボードAPIが利用できない場合のフォールバック
          const textarea = document.createElement('textarea')
          textarea.value = text
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
        } else {
          // クリップボードAPIを使用
          try {
            await navigator.clipboard.writeText(text)
            // alert('コピーしました: ' + text)
            // コピー成功時のトースト通知
            this.$toasted.show('コピーしました!', {
              theme: 'toasted-primary',
              position: 'top-center',
              type: 'success',
              duration: 1000,
            })
          } catch (err) {
            console.error('コピーに失敗しました', err)
          }
        }
      },
    },
  }
</script>

<style>
.masonryWrap {
  align: center;
  width: 100%;
}

.item {
  padding: 5px;
}

.item-image {
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
}

.flex-center {
  display: flex;
  justify-content: center; /* 横方向の中央寄せ */
  align-Items: center;     /* 縦方向の中央寄せ */
  flex-direction: column;  /* 子要素を縦方向に並べる */
  height: 100%;
}

.text-wrap {
  white-space: normal !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.v-btn.text-wrap {
  font-size: 9px; /* フォントサイズを調整 */
}

/* 大サイズの画面（デスクトップ）用のスタイル */
@media (min-width: 960px) {
  .v-btn.text-wrap {
    font-size: 14px; /* 大きい画面ではさらに大きいフォントサイズ */
  }
}

.narrow-container {
  max-width: 180px; /* ここで最大幅を設定 */
  margin: auto; /* 中央揃え */
}

.btn-margin-top {
  margin-top: 15px; /* 10pxのマージンを追加、必要に応じて調整 */
}

.text-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-container {
  display: flex;
  flex-direction: column; /* チェックボックスを縦に並べる */
  align-items: flex-start; /* 左寄せにする */
  gap: 5px; /* チェックボックス間の隙間を設定 */
}

.v-checkbox {
  margin: 0; /* チェックボックスの外側の余白を削除 */
  padding: 4px; /* チェックボックスの内側のパディングを調整 */
}

.v-checkbox .v-label {
  font-size: 12px; /* ラベルのフォントサイズを調整 */
  white-space: normal; /* テキストの折り返しを有効に */
}

/* スマートフォン画面での調整 */
@media (max-width: 600px) {
  .v-checkbox .v-label {
    font-size: 10px; /* スマホではさらに小さいフォントサイズ */
  }
}

.button-spacing {
  margin-left: 10px; /* 左にスペースを追加 */
  /* または margin-right: 10px; で右にスペースを追加 */
}

.image-dialog {
  max-height: 80vh; /* 画像の最大高さをビューポート高さの80%に設定 */
}

</style>
