<template>
  <div v-if="isVisible">
    <v-btn
      :icon="isMobile"
      color="primary"
      @click="clearFavorites"
    >
      <v-icon>mdi-delete</v-icon>
      <span v-if="!isMobile">{{ $t("message.delete_all_favorites") }}</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          <v-alert
            outlined
            shaped
            prominent
            text
            dense
            type="warning"
          >
            {{ $t("message.confirmation") }}
          </v-alert>
        </v-card-title>
        <v-card-text>
          {{ $t("message.confirmation_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"

            @click="confirmClearFavorites"
          >
            {{ $t("message.yes") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            {{ $t("message.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DeleteFavoritesButton',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      isMobile () {
        // 600px以下の画面幅をモバイルとみなす
        return window.innerWidth <= 600
      },
    },
    methods: {
      clearFavorites () {
        this.dialog = true
      },
      confirmClearFavorites () {
        this.$emit('confirm-delete')
        this.dialog = false
      },
    },
  }
</script>
