<template>
  <section class="container">
    <EstoreCard />

    <div class="input-area">
      <v-toolbar dark color="gray darken-3" class="mb-1">
        <!-- Twitterで検索 -->
        <v-text-field
          v-model="inputWordTwitter"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('message.search')"
          @input="onInputChangeTwitter"
          @click:clear="clearTagSelection"
          @change="clearTag"
        />
        <!-- スペーサーを追加して、次の要素（ボタン）を右寄せにします -->
        <v-spacer />
        <!-- お気に入り削除 -->
        <delete-favorites-button
          class="mr-3"
          :is-visible="shouldShowDeleteButton"
          @confirm-delete="handleDeleteFavorites"
        />
        <!-- 表形式 -->
        <table-button :table-data="searchedItemsTwitter" />
      </v-toolbar>
    </div>
    <!-- タグのリスト -->
    <div class="scrollable-container">
      <tag-chips
        ref="tagChipsRef"
        :items="items"
        @tag-clicked="setSearchWord"
      />
    </div>

    <div>
      <v-container>
        <v-row>
          <!-- Twitterの結果を表示 -->
          <v-col
            v-for="item in itemsTwitter"
            :key="item.tweet_id"
            class="center"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
          >
            <twitter-card
              :item="item"
              :user="user"
              :discord-button="false"
              @notShow="notShow"
              @favorite-updated="refreshFavorites"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- 無限スクロールのハンドラ -->
    <infinite-loading
      :identifier="infiniteIdTwitter"
      @infinite="infiniteHandlerTwitter"
    >
      <v-alert slot="no-results" class="mt-5 pt-5" dense text type="success">
        {{ $t('message.no-results') }}
      </v-alert>
      <v-alert slot="no-more" class="mt-5 pt-5" dense text type="success">
        {{ $t('message.no-more') }}
      </v-alert>
      <v-alert slot="error" class="mt-5 pt-5" dense outlined type="error">
        {{ $t('message.infinite-error') }}
      </v-alert>
    </infinite-loading>
  </section>
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading';
  import TwitterCard from './TwitterCard.vue';
  import TagChips from './TagChips.vue';
  import TableButton from './TableButton.vue';
  import DeleteFavoritesButton from './DeleteFavoritesButton.vue';

  export default {
    name: 'Over1000LikesTab',
    components: {
      InfiniteLoading,
      TwitterCard,
      TagChips,
      TableButton,
      DeleteFavoritesButton,
    },
    props: {
      user: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
        default: () => [],
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
      shouldShowDeleteButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputWordTwitter: '',
        itemsTwitter: [],
        searchedItemsTwitter: [],
        startTwitter: 0,
        perData: 4,
        getDataTwitter: 0,
        infiniteIdTwitter: 0,
      };
    },
    methods: {
      removeDuplicateItems(items) {
        const seenIds = new Set();
        return items.filter((item) => {
          if (!seenIds.has(item.tweet_id)) {
            seenIds.add(item.tweet_id);
            return true;
          }
          return false;
        });
      },
      handleDeleteFavorites() {
        // localStorageからお気に入りのデータをクリア
        localStorage.removeItem('favorites');
        // データを再取得（空になるはず）
        this.refreshFavorites();
      },
      clearTagSelection() {
        this.$refs.tagChipsRef.clearSelection();
      },
      clearTag() {
        this.$refs.tagChipsRef.clearTag();
      },
      // 検索結果を初期化
      initializeSearchResults() {
        this.infiniteIdTwitter += 1;
        this.searchedItemsTwitter = [...this.items];
        this.searchedItemsTwitter = this.removeDuplicateItems(
          this.searchedItemsTwitter
        );
        this.itemsTwitter = [];
        this.startTwitter = 0;
      },
      // Twitterデータのロード
      dataLoadTwitter() {
        this.itemsTwitter.push(
          ...this.searchedItemsTwitter.slice(
            this.startTwitter,
            this.startTwitter + this.perData
          )
        );
        this.startTwitter = this.startTwitter + this.perData;
      },
      // 無限スクロールのハンドラ
      async infiniteHandlerTwitter($state) {
        if (this.getDataTwitter === 0) {
          this.initializeSearchResults();

          this.getDataTwitter = 1;
        }
        this.dataLoadTwitter();
        if (this.searchedItemsTwitter.length <= this.startTwitter) {
          $state.complete();
        } else {
          $state.loaded();
        }
      },
      onInputChangeTwitter() {
        // 検索処理
        this.infiniteIdTwitter += 1;
        this.itemsTwitter = [];
        this.$nextTick(() => {
          this.initializeSearchResults();
          if (
            this.inputWordTwitter &&
            typeof this.inputWordTwitter === 'string'
          ) {
            this.searchedItemsTwitter = this.searchedItemsTwitter.filter(
              (item) =>
                (item &&
                  item.text &&
                  typeof item.text === 'string' &&
                  item.text
                    .toLowerCase()
                    .includes(this.inputWordTwitter.toLowerCase())) ||
                (item &&
                  item.user_name &&
                  typeof item.user_name === 'string' &&
                  item.user_name
                    .toLowerCase()
                    .includes(this.inputWordTwitter.toLowerCase()))
            );
          }
          this.dataLoadTwitter();
        });
      },

      // 削除済みツイートの排除
      notShow(item) {
        this.itemsTwitter = this.itemsTwitter.filter(
          (i) => i.tweet_id !== item.tweet_id
        );
      },
      setSearchWord(tag) {
        this.inputWordTwitter = tag;
        this.onInputChangeTwitter();
      },
      refreshFavorites() {
        this.$emit('favorite-updated'); // お気に入りのデータが更新されたことを通知
      },
    },
  };
</script>

<style scoped>
  .scrollable-container {
    overflow-x: auto;
    white-space: nowrap;
  }
</style>
