<template>
  <div class="user-container">
    <div
      v-if="userInfo"
      class="user-profile"
    >
      <img
        :src="userInfo.avatar_url"
        class="user-avatar"
      >
      <span class="user-name">
        {{ userInfo.user_name }}
      </span>
      <router-link :to="{ name: 'Artwork', params: { id: userInfo.user_id, tabid: tab } }">
        <v-btn
          class="mr-0 ml-3"
          color="primary"
          min-width="100"
          rounded
        >
          {{ $t("message.view_the_artwork") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'User',
    props: {
      userId: {
        type: String,
        required: true,
      },
      tab: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        userInfo: null,
      }
    },
    async created () {
      const response = await axios.get(`../../../user/all_users_master.json?timestamp=${new Date().getTime()}`)
      this.userInfo = response.data.find(user => user.user_id === this.userId)
    },
    methods: {
      escapeHtml (text) {
        const map = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#039;',
        }
        return text.replace(/[&<>"']/g, function (m) { return map[m] })
      },
    },
  }
</script>

<style scoped>
.user-container {
  display: flex;
  justify-content: center;
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 50px;  /* Change as needed */
  height: 50px;  /* Change as needed */
  border-radius: 50%;  /* Makes the image circular */
  margin-right: 15px; /* Adjust the gap */
}

.user-name {
  margin-right: 15px; /* Adjust the gap */
}

</style>
