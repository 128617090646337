<template>
  <div>
    <a
      href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=889431184"
      rel="nofollow"
    ><v-img
      max-width="20vw"
      min-width="300px"
      contain
      src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=889431184"
      border="0"
    /></a>
    <a
      href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=887339517&vc_url=https%3A%2F%2Fstore.jp.square-enix.com%2Fitem%2FSEDL_1199.html"
      rel="nofollow"
    ><img
      src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=887339517"
      height="1"
      width="0"
      border="0"
    >『黄金のレガシー』予約特典は7/1まで！(e-STORE)</a>
  </div>
</template>

<script>
  export default {
    name: 'EstoreCard',
  }
</script>
