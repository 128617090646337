<template>
  <v-btn
    color="blue darken-1"
    text
    :href="messageUrl"
    target="_blank"
  >
    <img
      :src="'../../../discord-mark-blue.png'"
      height="20"
    >
  </v-btn>
</template>

<script>
  export default {
    name: 'DiscordButton',
    props: {
      messageUrl: { // DiscordメッセージのURL
        type: String,
        required: true,
      },
    },
  }
</script>
