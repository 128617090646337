<template>
  <v-card
    v-if="showCard"
    min-height="500px"
    min-width="300px"
    align="center"
    class="myTransparentCard"
    outlined
  >
    <tweet
      :id="item.tweet_id"
      error-message=" "
      :options="{ align: 'center' }"
      @TweetError="notShow(item)"
    >
      <spinner />
    </tweet>

    <favorite-button
      v-if="favorite"
      :tweet-id="item.tweet_id"
      :initial-favorite="isFavorite"
      @favorite-toggled="handleFavoriteToggle"
      @favorite-updated="refreshFavorites"
    />

    <!-- ユーザーコンポーネントの表示 -->
    <user
      v-if="user"
      :user-id="item.user_id"
      tab="0"
    />

    <!-- Discordで見るボタン -->
    <v-row class="justify-end">
      <discord-button
        v-if="discordButton"
        :message-url="item.message_url"
      />
    </v-row>
  </v-card>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import Spinner from 'vue-simple-spinner'
  import User from './User.vue'
  import DiscordButton from './DiscordButton.vue'
  import FavoriteButton from './FavoriteButton.vue'

  export default {
    name: 'TwitterCard',
    components: {
      Tweet,
      Spinner,
      User,
      DiscordButton,
      FavoriteButton,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      user: {
        type: Boolean,
        default: false,
      },
      favorite: {
        type: Boolean,
        default: true,
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        showCard: true, // v-cardの表示を管理するためのデータプロパティ
        isFavorite: false, // お気に入りの状態を管理するためのデータプロパティ
      }
    },
    created () {
      const favorites = JSON.parse(localStorage.getItem('favorites') || '[]')
      this.isFavorite = favorites.some(favItem => favItem.tweet_id === this.item.tweet_id)
      // console.log(favorites)
      // console.log(this.item.tweet_id)
    },
    methods: {
      notShow: function (item) {
        this.showCard = false // エラーが発生したときにshowCardをfalseに設定し、v-cardを非表示にする
        this.$emit('notShow', item)
      },
      handleFavoriteToggle (tweetId, isFavorite) {
        let favorites = JSON.parse(localStorage.getItem('favorites') || '[]')

        // すでに存在する場合は、そのitemを配列から削除
        favorites = favorites.filter(favItem => favItem.tweet_id !== tweetId)

        // お気に入りとして追加する場合、配列にitem全体を追加
        if (isFavorite) {
          favorites.push(this.item)
        }

        localStorage.setItem('favorites', JSON.stringify(favorites))
      },
      refreshFavorites () {
        this.$emit('favorite-updated') // お気に入りのデータが更新されたことを通知
      },
    },
  }
</script>

<style scoped>
.myTransparentCard {
  border: none !important;
  background-color: transparent !important;
}
</style>
