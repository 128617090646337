<template>
  <!-- コンテナーディビジョン -->
  <div>
    <v-app-bar v-if="userData" rounded class="centered-content">
      <v-spacer class="spacer-left-adjust" />
      <!-- 左のズレを調整 -->

      <div class="img-text-container">
        <div class="circular-image">
          <v-img
            :src="userData.avatar_url"
            contain
            aspect-ratio="1"
            class="rounded-img"
          />
        </div>
        <h4 class="text-h4 centered-text mb-2 text--secondary">
          {{ userData.user_name }}
        </h4>
      </div>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="showButton"
            v-clipboard:copy="message"
            v-clipboard:error="onError"
            type="button"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ btnText }}</span>
      </v-tooltip>
    </v-app-bar>

    <!-- タブを作成します。タブの値は "tab" 変数に格納されます -->
    <v-tabs v-model="localTabid" show-arrows>
      <!-- タブのスライダーを黄色に設定します -->
      <v-tabs-slider color="yellow" />

      <!-- Twitter項目が存在する場合、Twitterタブを表示します -->
      <v-tab v-if="showTwitter" class="custom-tab-font center-tab-content">
        <v-icon>
          mdi-twitter
        </v-icon>
        <span class="tab-text">Twitter</span>
      </v-tab>

      <!-- Youtube項目が存在する場合、Youtubeタブを表示します -->
      <v-tab v-if="showYoutube" class="custom-tab-font center-tab-content">
        <v-icon>
          mdi-youtube
        </v-icon>
        <span class="tab-text">Youtube</span>
      </v-tab>

      <!-- Discord項目が存在する場合、Discordタブを表示します -->
      <v-tab v-if="showDiscord" class="custom-tab-font center-tab-content">
        <img :src="discordIcon" width="30" height="20" />
        <span class="tab-text">Discord</span>
      </v-tab>

      <!-- Popular項目が存在する場合、Twitterタブを表示します -->
      <v-tab v-if="showPopular" class="custom-tab-font center-tab-content">
        <v-icon>
          mdi-star-shooting-outline
        </v-icon>
        <span class="tab-text">Popular</span>
      </v-tab>

      <!-- Over1000likes項目が存在する場合、Twitterタブを表示します -->
      <v-tab
        v-if="showOver1000likes"
        class="custom-tab-font center-tab-content"
      >
        <v-icon>
          mdi-crown
        </v-icon>
        <span class="tab-text">1000likes</span>
      </v-tab>
    </v-tabs>

    <!-- タブの内容を表示します -->
    <v-tabs-items v-model="localTabid">
      <v-tab-item v-if="showTwitter">
        <v-card min-height="1500px">
          <twitter-tab
            :user="user"
            :items="twitterItems"
            :discord-button="discordButton"
          />
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="showYoutube">
        <v-card min-height="1500px">
          <youtube-tab
            :user="user"
            :items="youtubeItems"
            :discord-button="discordButton"
          />
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="showDiscord">
        <v-card min-height="1500px">
          <discord-tab
            :user="user"
            :items="discordItems"
            :discord-button="discordButton"
          />
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="showPopular">
        <v-card min-height="1500px">
          <popular-tab
            :user="user"
            :items="matchedPopularItems"
            :discord-button="discordButton"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="showOver1000likes">
        <v-card min-height="1500px">
          <over-1000-likes-tab
            :user="user"
            :items="matchedOver1000LikesItems"
            :discord-button="discordButton"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  // コンポーネントをインポートします
  import DiscordTab from './DiscordTab.vue';
  import TwitterTab from './TwitterTab.vue';
  import YoutubeTab from './YoutubeTab.vue';
  import PopularTab from './PopularTab.vue';
  import Over1000LikesTab from './Over1000LikesTab.vue';
  import axios from 'axios';

  // コンポーネントをエクスポートします
  export default {
    name: 'ArtworkTabs',
    components: {
      DiscordTab,
      TwitterTab,
      YoutubeTab,
      PopularTab,
      Over1000LikesTab,
    },
    props: {
      showButton: {
        type: Boolean,
        default: false,
      },
      // プロパティを設定します。デフォルトは空の配列です
      twitterItems: {
        type: Array,
        default: () => [],
      },
      youtubeItems: {
        type: Array,
        default: () => [],
      },
      discordItems: {
        type: Array,
        default: () => [],
      },
      popularItems: {
        type: Array,
        default: () => [],
      },
      over1000LikesItems: {
        type: Array,
        default: () => [],
      },
      user: {
        type: Boolean,
        default: false,
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
      tabid: {
        type: Number,
        default: 0,
      },
      showTwitter: {
        type: Boolean,
        default: true,
      },
      showYoutube: {
        type: Boolean,
        default: true,
      },
      showDiscord: {
        type: Boolean,
        default: true,
      },
      showPopular: {
        type: Boolean,
        default: true,
      },
      showOver1000likes: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      // 初期データを設定します
      return {
        discordIcon: '../../../discord.svg',
        localTabid: this.tabid, // propsをローカルデータにコピー
        message: '',
        btnText: '',
        userData: null,
        matchedPopularItems: [],
        matchedOver1000LikesItems: [],
      };
    },
    created: async function () {
      // ユーザーデータを取得
      await this.fetchUserData();

      this.btnText = this.$t('message.copy_the_url');
      this.message = 'https://ff14eden.work/#' + this.$route.path;
    },
    methods: {
      // popularItemsから特定のtwitter_user_idに基づいてuser_screen_nameを検索
      searchInPopularItems() {
        const twitterUserId = this.userData.twitter_user_id[0];
        console.log(twitterUserId);
        console.log(this.popularItems);
        this.matchedPopularItems = this.popularItems.filter((item) => {
          // user_screen_name が存在する場合はその値で検索
          if (item.user_screen_name) {
            return item.user_screen_name === twitterUserId;
          }
          // user_screen_name が存在しない場合は tweet_url で検索
          else if (item.tweet_url) {
            return item.tweet_url.includes(twitterUserId);
          }
          return false; // user_screen_name も tweet_url も適切でない場合は false
        });

        // マッチングしたアイテムをログに出力（または他の処理）
        console.log('Matched Items:', this.matchedPopularItems);
      },
      // Over1000LikesItemsから特定のtwitter_user_idに基づいてuser_screen_nameを検索
      searchInOver1000LikesItems() {
        const twitterUserId = this.userData.twitter_user_id[0];
        console.log(twitterUserId);
        console.log('over1000LikesItems');
        console.log(this.over1000LikesItems);
        this.matchedOver1000LikesItems = this.over1000LikesItems.filter(
          (item) => {
            // user_screen_name が存在する場合はその値で検索
            if (item.user_screen_name) {
              return item.user_screen_name === twitterUserId;
            }
            // user_screen_name が存在しない場合は tweet_url で検索
            else if (item.tweet_url) {
              return item.tweet_url.includes(twitterUserId);
            }
            return false; // user_screen_name も tweet_url も適切でない場合は false
          }
        );

        // マッチングしたアイテムをログに出力（または他の処理）
        console.log('Matched Items:', this.matchedOver1000LikesPopularItems);
      },
      async fetchUserData() {
        try {
          const response = await axios.get(
            `../../../user/all_users_master.json?timestamp=${new Date().getTime()}`
          );
          const allUsers = response.data;

          // 仮定: userのIDがthis.$route.params.idで利用可能
          const userId = this.$route.params.id;
          // console.log(userId)

          // 指定されたIDのユーザーを見つける
          this.userData = allUsers.find((user) => user.user_id === userId);

          // userDataが見つかった後、popularItems内で関連するユーザーを検索
          if (this.userData) {
            this.searchInPopularItems();
            this.searchInOver1000LikesItems();
          }
        } catch (e) {
          console.error('ユーザーデータの取得に失敗しました:', e);
        }
      },
      onError: function (e) {
        this.btnText = 'エラーが発生しました';
        console.log('Failed to copy texts');
      },
    },
  };
</script>

<style scoped>
  .centered-content {
    align-items: center; /* 縦方向の中央揃え */
  }
  .centered-text {
    margin: auto 0; /* 上下のマージンを自動に設定 */
  }
  .img-text-container {
    display: flex; /* Flexboxを適用 */
    align-items: center; /* アイテムを中央に揃える */
    gap: 10px; /* アイテム間の間隔を設定 */
  }
  .circular-image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
  }
  .rounded-img {
    border-radius: 50%;
  }
  .app-bar-custom-height {
    margin: 10px;
  }
  .spacer-left-adjust {
    margin-left: 30px !important;
  }
  .v-tabs .v-tab.custom-tab-font {
    font-size: 10px !important;
  }
  .center-tab-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* 縦方向にアイコンとテキストを配置 */
  }

  .tab-text {
    display: inline;
  }

  @media (max-width: 600px) {
    .tab-text {
      display: none;
    }
  }
</style>
