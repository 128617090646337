<template>
  <div>
    <v-btn
      color="primary"
      @click="dialog = true"
    >
      <v-icon>mdi-table</v-icon>
      <span>{{ $t("message.show_furniture_list") }}</span>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <div>
          <v-card-text style="padding: 3px;">
            <v-simple-table
              dense
              fixed-header
              height="68vh"
              width="90vw"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      style="white-space: nowrap; width: 30px;"
                    >
                      {{ $t("message.image") }}
                    </th>
                    <th
                      class="text-left"
                      style="white-space: nowrap; "
                    >
                      {{ $t("message.name") }}
                    </th>
                    <th
                      class="text-left"
                      style="white-space: nowrap; "
                    >
                      {{ $t("message.type") }}
                    </th>
                    <th
                      class="text-left"
                      style="white-space: nowrap; width: 50px;"
                    >
                      {{ $t("message.quantity") }}
                    </th>
                    <th
                      class="text-left"
                      style="white-space: nowrap; width: 50px;"
                    >
                      {{ $t("message.delete") }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="item in tableData"
                    :key="item.tweet_id"
                  >
                    <td>
                      <v-img
                        :src="item.imagePath"
                        width="30px"
                        height="30px"
                      />
                    </td>
                    <td>
                      <v-icon
                        small
                        @click.stop="copyToClipboard(item.name)"
                      >
                        mdi-content-copy
                      </v-icon>
                      {{ item.name || '' }}
                    </td>
                    <td>{{ item.type || '' }}</td>
                    <td>
                      {{ item.num || '' }}
                    </td>
                    <td>
                      <v-btn
                        icon
                        @click="deleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer />
          <!-- お気に入り削除 -->
          <delete-furniture-list
            class="mr-3"
          />
          <v-btn
            icon
            color="secondary"
            class="button-spacing"
            @click="dialog = false"
          >
            <v-icon
              small
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'FurnitureList',
    data () {
      return {
        tableData: {},
        dialog: false,
      }
    },
    computed: {
    },
    watch: {
      dialog (newVal) {
        if (newVal) {
          this.loadList()
        }
      },
    },
    methods: {
      deleteItem (deletedItem) {
        // localStorageからリストを取得
        let furnitureList = localStorage.getItem('furnitureList')
        if (furnitureList) {
          furnitureList = JSON.parse(furnitureList)

          // 削除されたアイテムをリストから取り除く
          furnitureList = furnitureList.filter(item => item.name !== deletedItem.name)

          // 更新されたリストをlocalStorageに保存
          localStorage.setItem('furnitureList', JSON.stringify(furnitureList))
        }
        this.loadList()
      },
      // ローカルストレージからリストを復元するメソッド
      loadList () {
        const savedList = localStorage.getItem('furnitureList')
        if (savedList) {
          this.tableData = JSON.parse(savedList)
        }
      },

      async copyToClipboard (text) {
        if (!navigator.clipboard) {
          // クリップボードAPIが利用できない場合のフォールバック
          const textarea = document.createElement('textarea')
          textarea.value = text
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
        } else {
          // クリップボードAPIを使用
          try {
            await navigator.clipboard.writeText(text)
            // コピー成功時のトースト通知
            this.$toasted.show('コピーしました!', {
              theme: 'toasted-primary',
              position: 'top-center',
              type: 'success',
              duration: 1000,
            })
          } catch (err) {
            console.error('コピーに失敗しました', err)
          }
        }
      },
      downloadData () {
        const header = 'Image,Name,Num\n'
        const csvData = this.tableData.map(item => {
          return [
            item.user_name || '',
            item.tweet_url || '',
            (item.adress || []).join(', '),
            item.size || '',
            item.aetheryteJp || '',
            item.aetheryteEn || '',
            item.aetheryteKo || '',
          ].join(',')
        }).join('\n')
        const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'data.csv')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
    },
  }
</script>

<style scoped>
/* CSSでテーブルの横スクロールバーを常に表示 */
table {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px; /* 左右のパディングを8pxに設定 */
}

</style>
