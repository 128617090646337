var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-color-picker',{attrs:{"flat":"","hide-canvas":"","hide-inputs":"","hide-sliders":"","value":_vm.internalColor,"swatches":_vm.getAllColors()},on:{"input":_vm.updateColor}}),_c('v-card-actions',[_c('v-chip',{style:({
          backgroundColor: _vm.internalColor,
          color: '#000',
          fontFamily: 'Mochiy Pop One, sans-serif',
        }),attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.selectedColorName || '選択中')+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("close")])],1),_c('v-expansion-panels',_vm._l((_vm.categories),function(category,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{style:({
            backgroundColor: category.colors[0].color,
            color: '#000',
            fontFamily: 'Mochiy Pop One, sans-serif',
          })},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('v-expansion-panel-content',[_c('v-list',{staticClass:"scrollable-list"},_vm._l((category.colors),function(swatch,swatchIndex){return _c('v-list-item',{key:swatchIndex,on:{"click":function($event){return _vm.selectColor(swatch)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{style:({
                      color: '#000',
                      fontFamily: 'Mochiy Pop One, sans-serif',
                    })},[_vm._v(" "+_vm._s(swatch.name)+" ")])])],1),_c('v-list-item-avatar',{attrs:{"color":swatch.color}})],1)}),1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }