<template>
  <!-- タブアイテムの開始 -->
  <section class="container">
    <EstoreCard />
    <!-- 検索欄 -->
    <div class="input-area">
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <v-text-field
          v-model="inputWordDiscord"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('message.search')"
          @input="onInputChangeDiscord"
          @click:clear="clearTagSelection"
          @change="clearTag"
        />
      </v-toolbar>
    </div>

    <!-- タグのリスト -->
    <div class="scrollable-container">
      <tag-chips
        ref="tagChipsRef"
        :items="items"
        @tag-clicked="setSearchWord"
      />
    </div>

    <!-- Discordカードリスト -->
    <div>
      <v-container>
        <v-row>
          <v-col
            v-for="(item, index) in itemsDiscord"
            :key="index"
            cols="12"
            sm="12"
            md="10"
            lg="6"
            xl="4"
          >
            <discord-card
              :item="item"
              :user="user"
              :discord-button="discordButton"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- 無限スクロール -->
    <infinite-loading
      :identifier="infiniteIdDiscord"
      @infinite="infiniteHandlerDiscord"
    >
      <v-alert
        slot="no-results"
        dense
        text
        type="success"
      >
        {{ $t("message.no-results") }}
      </v-alert>
      <v-alert
        slot="no-more"
        dense
        text
        type="success"
      >
        {{ $t("message.no-more") }}
      </v-alert>
      <v-alert
        slot="error"
        dense
        outlined
        type="error"
      >
        {{ $t("message.infinite-error") }}
      </v-alert>
    </infinite-loading>
  </section>
  <!-- タブアイテムの終了 -->
</template>

<script>
  import InfiniteLoading from 'vue-infinite-loading'
  import DiscordCard from './DiscordCard.vue'
  import TagChips from './TagChips.vue'

  export default {
    name: 'DiscordTab',
    components: {
      InfiniteLoading,
      DiscordCard,
      TagChips,
    },
    props: {
      user: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array,
        default: () => [],
      },
      discordButton: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        inputWordDiscord: '',
        itemsDiscord: [],
        searchedItemsDiscord: [],
        startDiscord: 0,
        perData: 6,
        getDataDiscord: 0,
        infiniteIdDiscord: 0,
      }
    },
    methods: {
      clearTagSelection () {
        this.$refs.tagChipsRef.clearSelection()
      },
      clearTag () {
        this.$refs.tagChipsRef.clearTag()
      },
      // 検索結果を初期化
      initializeSearchResults () {
        this.infiniteIdDiscord += 1
        this.searchedItemsDiscord = [...this.items]
        this.itemsDiscord = []
        this.startDiscord = 0
      },
      // データの読み込み
      dataLoadDiscord () {
        this.itemsDiscord.push(
          ...this.searchedItemsDiscord.slice(
            this.startDiscord,
            this.startDiscord + this.perData,
          ),
        )
        this.startDiscord = this.startDiscord + this.perData
      },
      // 無限スクロールのハンドラー
      async infiniteHandlerDiscord ($state) {
        if (this.getDataDiscord === 0) {
          this.initializeSearchResults()

          this.getDataDiscord = 1
        }
        this.dataLoadDiscord()
        if (this.searchedItemsDiscord.length <= this.startDiscord) {
          $state.complete()
        } else {
          $state.loaded()
        }
      },

      // 入力欄の変更時の処理
      onInputChangeDiscord () {
        // 検索処理
        this.infiniteIdDiscord += 1
        this.itemsDiscord = []
        this.$nextTick(() => {
          this.initializeSearchResults()
          if (this.inputWordDiscord && typeof this.inputWordDiscord === 'string') {
            this.searchedItemsDiscord = this.searchedItemsDiscord.filter(item =>
              item && item.text && typeof item.text === 'string' && item.text.toLowerCase().includes(this.inputWordDiscord.toLowerCase()),
            )
          }
          this.dataLoadDiscord()
        })
      },
      setSearchWord (tag) {
        this.inputWordDiscord = tag
        this.onInputChangeDiscord()
      },
    },
  }
</script>

<style scoped>
.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
