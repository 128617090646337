<template>
  <v-chip
    dark
    class="ma-2"
    label
    text-color="primary"
    color="primary"
    outlined
    @click="sendMessageCommissionClosed"
  >
    <v-icon
      left
      color="#00acee"
    >
      mdi-twitter
    </v-icon>
    {{ $t("message.tweet_commission_closed") }}
  </v-chip>
</template>

<script>
  export default {
    name: 'CommissionClosed',

    methods: {
      sendMessageCommissionClosed () {
        window.open(
          'https://twitter.com/intent/tweet?text=@tos &hashtags=HousingEdenCommissionClosed',
          '_blank',
        )
      },
    },
  }
</script>
